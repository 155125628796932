import React from 'react'
import Download from './download'
import { Link } from 'react-router-dom'

import heart from '../assets/heart.svg'
import footerLogo from '../assets/footer-logo.svg'
import googlePlay from '../assets/googlePlay.png'
import appStore from '../assets/APPSTORE.png'
import './Footer/footer.css';
import { isIOS, isAndroid, isMobile } from 'react-device-detect'
import electronics from '../assets/electronics.jpg'
import fashion from '../assets/fashion.jpg'
import food from '../assets/food.jpg'
import beauty from '../assets/beauty.jpg'
import WakandaLogoWhite from '../assets/Wakanda-Logo-white.svg'



let categories = [
    { name: 'Electronics', 'image': electronics, select: 'Phones' },
    { name: 'Fashion', 'image': fashion, select: 'Bags' },
    { name: 'Food', 'image': food, select: 'Fresh Food' },
    { name: 'Beauty', 'image': beauty, select: 'Hair' },
    { name: 'Cars', 'image': fashion, select: 'New Cars' },
    { name: 'Health', 'image': fashion, select: 'Pharmacies' },
    { name: 'Handyman', 'image': fashion, select: 'Plumbing' },
    { name: 'Appliances', 'image': fashion, select: 'Appliances' },


]


const displayprice = (price, type, currency) => {

    if (currency == 'NGN') {
        currency = '₦'
    }
    if (currency == 'GHS') {
        currency = '₵'
    }



    let pString = Math.round(price).toString();
    if (pString.length > 8) {
        pString = pString.slice(0, pString.length - 6) + "M";
    } else if (pString.length > 6) {
        pString = Number(pString.slice(0, pString.length - 5)) / 10;
        pString = pString.toString() + "M";
    } else if (pString.length > 4) {
        pString = pString.slice(0, pString.length - 3) + "K";
    }


    let priceString = pString;
    if (type) {
        if (type == "Starting From") {
            priceString = "Starting From " + currency + " " + pString;
        }
        else if (type == "Fixed Price") {
            priceString = currency + " " + pString;
        }
        else {
            priceString =
                currency + " " + pString + " (" + type + ")";
        }
        if (type == "Free") {
            priceString = "FREE";
        }
        if (type == "NONE") {
            priceString = " ";
        }
        return priceString
    }
    //  return pString
}




const uniformImageHolder = (url, width, height, borderStyle, radius) => {
    return (
        <div class='col-md-12 p-0 m-0'>
            <div
                style={{
                    backgroundImage: `url(${url})` || "",
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    minHeight: height || 200,
                    width: width || "100%",
                    borderRadius: radius || 20,
                    marginTop: 10,
                    border: borderStyle || ''
                }}>

            </div>
        </div>
    )
}
const nonUniformImageHolder = (url, width, height, borderStyle, radius) => {
    return (
        <div class='col-md-12 p-0 m-0'>
            <div
                style={{
                    backgroundImage: `url(${url})` || "",
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    minHeight: height || 200,
                    width: width || "100%",
                    borderTopLeftRadius: radius || 20,
                    borderTopRightRadius: radius || 20,
                    marginTop: 10,
                    border: borderStyle || ''
                }}>

            </div>
        </div>
    )
}


const Header = (src, bgColor) => {
    return (
        <div className="nw1-product-smaller-header" style={{ backgroundColor: bgColor }} >
            
                <Link to='/' style={{ textDecoration: 'none', }}>
                    <div style={{
                        backgroundImage: `url(${src})`,
                        width: 190,
                        height: 45,
                        backgroundPosition: 'top left',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        
                    }} className=' col-sm-12 my-2'>
                    </div>
                </Link>

        </div>
    )
}


const JobCard = (job, width) => {
    return (
        <div class='container col-md-4 ' style={{
            flexDirection: "column", paddingTop: 20,
        }}>
            <div>
                <Link to={`/jobs/${job.name}`} target="_blank" style={{ textDecoration: 'none' }}>
                    <div style={{
                        backgroundColor: job.color || "green",
                        width: width || 200,
                        height: width || 200,
                        padding: 20,
                        textAlign: 'center',
                        borderRadius: 20,
                        paddingBottom: 20,
                        display: 'flex',
                        alignItems: 'flex-end',
                        position: 'relative',
                        overflow: 'hidden'
                    }}>
                        {/* <div style={{
                            backgroundImage: `url(${job.image})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                            width: '100%',
                            height: 300,
                            marginBottom: 20}}>
                        </div> */}




                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textOverflow: 'ellipsis',
                                position: 'absolute',
                                backgroundColor: "black",
                                opacity: 0.15,
                                width: (width) || 200,
                                height: (width) || 100,
                                padding: 20,
                                textAlign: 'center',
                                borderRadius: (width / 2) || 50,
                                top: -(width / 1.8) || -25,
                                right: -(width / 2) || -25
                            }}></div>
                        <small class='text-truncate text-wrap'
                            style={{
                                color: 'white', textAlign: "left", lineHeight: 1.2,
                                fontSize: 14, fontWeight: "regular",
                            }}><div style={{ paddingBottom: 10 }}><img src={job.image} alt="" width='50' /></div>{job.name}</small>
                    </div>
                </Link>
            </div>

        </div>)
}


// const Banner1 = (image, text1, text2, buttonText, bColor) => {
//     return (
//         <div class='container'
//             style={{
//                 display: "flex",
//                 flexDirection: "row",
//                 minHeight: 300,
//                 padding: 30,
//                 paddingBottom: 10,
//                 backgroundColor: bColor || "#00A85A",
//                 borderRadius: 20,
//                 alignItems: 'center',
//                 justifyContent: 'space-evenly'
//             }}>
//             <div className="row"></div>
//             <div class='col-md-5'
//                 style={{
//                     padding: 20,
//                 }}
//             >
//                 <h3 class='text-wrap' style={{ color: "white", textAlign: "left", fontSize: '2vw' }}>{text1 || 'Hi! This is a Header'}</h3>
//                 <p class='text-wrap' style={{ color: "white", textAlign: "left", lineHeight: 1, fontSize: '1.5vw' }}>{text2 || 'Malogbe yen wa o!'}</p>
//                 {/* <div className=' col-sm-12 col-md-12 col-lg-2 col-xl-2 mb-1'><button class='btn btn-group'
//                     style={{
//                         background: '#A9D046 0% 0% no-repeat padding-box',

//                         font: 'Bold 16px/20px Raleway', color: 'white',
//                         letterSpacing: 0.84
//                     }}
//                 ><small>  {buttonText || 'Learn More'}</small></button> </div> */}
//                 <div className='mt-4'>
//                     <button type="button" class="btn btn-outline-light"> GET STARTED</button></div>

//             </div>
//             <div class='col-md-5 mt-2'
//                 style={{
//                     backgroundImage: `url(${image})` || "",
//                     backgroundSize: 'cover',
//                     backgroundPosition: 'center center',
//                     backgroundAttachment: 'static',
//                     backgroundRepeat: 'no-repeat',
//                     height: 350,
//                     //textAlign: 'center',

//                     display: 'flex',
//                     alignItems: 'flex-end'
//                 }}>

//             </div>
//         </div >)
// }

const Footer2 = (bgCol) => {
    return (
        <footer class="page-footer pt-4" style={{ marginBottom: -2, width: "100%" }}>
            <div class="text-center  p-3 pr-4" style={{ backgroundColor: bgCol }}>
                <div class="row pt-4">
                    <div class="col-md-3 mt-md-0 mt-3 " style={{ textAlign: 'center' }}>
                        <div style={{
                            backgroundImage: `url(${footerLogo})`,
                            height: 80,
                            backgroundPosition: 'center center',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat'
                        }} className=''>
                        </div>
                    </div>


                    <hr class=" w-100 d-md-none pb-3" />
                    <div class="col-md-3 mb-md-0 mb-3">
                        <ul class="list-unstyled">
                            <li>
                                <p style={{ color: 'white' }}><small>Terms of use</small></p>
                            </li>
                            <li>
                                <p style={{ color: 'white' }}><small>Privacy and Security</small></p>
                            </li>
                            <li>
                                <p style={{ color: 'white' }}><small>Feedback</small></p>
                            </li>
                        </ul>

                    </div>

                    <div class="col-md-6 mb-md-0 mb-3 px-4 text-md-right" >

                        <ul class="list-unstyled ">
                            <li>
                                <a href="#!"><img src={googlePlay} width='100' alt='google play' /></a><a href="#!">&nbsp;<img src={appStore} width='100' alt='appstore' /></a>
                            </li>
                            <li>
                                <p className='text-white pt-4' style={{ fontSize: 14 }}>CONNECT WITH US ON <span><i class="fab fa-facebook"></i>&nbsp;&nbsp;&nbsp;&nbsp;<i class="fab fa-instagram icon-large"></i>&nbsp;&nbsp;&nbsp;&nbsp;<i class="fab fa-twitter icon-large"></i></span></p>
                            </li>

                        </ul>

                    </div>

                </div>

            </div>
            <div class="footer-copyright text-center " style={{ backgroundColor: '#000000' , color:'#f3f3f3'}}><p className="p-1" style={{ fontSize: 12, marginBottom: 0 }}><small>Made with <img src={heart} alt="heart" width="10" /> by African Founders</small></p>
            </div>
        </footer>
    )
}
const Banner1 = (image, text1, text2, buttonText, bColor) => {
    return (
        <div class='container' style={{
            backgroundColor: bColor || "#00A85A", borderRadius: 20,
            alignItems: 'center', padding: 20,
        }}>
            <div class="d-md-flex flex-md-equal w-100  align-items-center pl-md-3">
                <div class="   px-3  px-md-5  text-white overflow-hidden">
                    <div class="pt-md-4 pt-4 animate__animated animate__backInLeft animate__slower ">
                        <h2 class="display-5">{text1 || 'Hi! This is a Header'}</h2>
                        <p style={{ fontSize: 12 }}>{text2 || 'Malogbe yen wa o!'}</p>
                        <Link to='/wakandaacademy'>
                            <div className='mt-4'>
                                <button type="button" class="btn btn-outline-light "> {buttonText || 'GET STARTED'}</button>
                            </div>
                        </Link>
                    </div>
                </div>
                <div class="  px-md-5 text-center overflow-hidden mt-4  align-items-center  zoomin" style={{
                    backgroundImage: `url(${image})` || "",
                    backgroundSize: 'contain',
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    height: 280
                }}>

                    <div class=" box-shadow mx-auto" style={{ width: '80vw', borderRadius: '21px 21px 0 0' }}></div>
                </div>
            </div>

        </div >)
}




const Banner2 = (image, text1, text2, buttonText, bColor) => {
    return (
        <div class='container' style={{
            backgroundColor: bColor || "#00A85A", borderRadius: 20,
            alignItems: 'center',
        }}>
            <div class="d-md-flex flex-md-equal w-100  align-items-center pl-md-3">

                <div class="  px-md-5 text-center overflow-hidden align-items-center zoomin" style={{
                    backgroundImage: `url(${image})` || "",
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    height: 320,

                    //textAlign: 'center',

                }}>

                    <div class=" mx-auto" style={{ width: '80vw' }}></div>
                </div>
                <div class="   px-3  px-md-5  text-white overflow-hidden">
                    <div class="pt-md-4 pt-4 animate__animated animate__backInRight animate__slow">
                        <p class='text-wrap'
                            style={{
                                color: 'var(--unnamed-color-333333)',
                                textAlign: 'left',
                                font: 'Bold 20px Raleway',
                                letterSpacing: '1.23px',
                                color: '#333333',
                                opacity: 1,
                            }}>
                            {text1 || 'Hi! This is a Header'}
                        </p>
                        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                            {text2.length > 0 && text2.map((item, id) => {
                                return (
                                    <p class='text-wrap' style={{ color: "black", textAlign: "left", lineHeight: 0.9 }} key={id}>
                                        <small> {item} </small>
                                    </p>
                                )
                            })}
                        </div>
                    </div>

                </div>

            </div>

        </div >)
}
const BannerCat = (image, text1, text2, buttonText, bColor) => {
    return (
        <div class='container' style={{
            backgroundColor: bColor || "#00A85A", borderRadius: 20,
            alignItems: 'center',overflow:'hidden'
        }}>
            <div class="d-md-flex flex-md-equal w-100  align-items-center pl-md-3 ml-md-n5 ">
                <div class="  px-md-5 text-center overflow-hidden align-items-center zoomin" style={{
                    backgroundImage: `url(${image})` || "",
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    height: 320,
                   

                    //textAlign: 'center',

                }}>

                    <div class=" mx-auto" style={{ width: '80vw' }}></div>
                </div>
                <div class="   px-3  px-md-5  text-white overflow-hidden">
                    <div class="pt-md-4 pt-4 animate__animated animate__backInRight animate__slow">
                        <p class='text-wrap'
                            style={{
                                color: 'var(--unnamed-color-333333)',
                                textAlign: 'left',
                                font: 'Bold 20px Raleway',
                                letterSpacing: '1.23px',
                                color: '#333333',
                                opacity: 1,
                            }}>
                            {text1 || 'Hi! This is a Header'}
                        </p>
                        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                            {text2.length > 0 && text2.map((item, id) => {
                                return (
                                    <p class='text-wrap' style={{ color: "#333333", textAlign: "left", lineHeight: 0.9 }} key={id}>
                                        <small> {item} </small>
                                    </p>
                                )
                            })}
                            {/* <a href={isIOS ? 'https://apps.apple.com/ng/app/wakanda-market/id1451309384' : 'https://play.google.com/store/apps/details?id=com.african_founders.Wakanda'} style={{ textDecoration: 'none' }}>
                                <div className='mt-4'>
                                    <button type="button" class="btn btn-outline-light "> {buttonText || 'GET STARTED'}</button>
                                </div>
                          </a> */}
                        </div>
                    </div>

                </div>

            </div>

        </div >)
}
const BannerJobs = (image, text1, text2, buttonText, bColor) => {
    return (
        <div class='container' style={{
            backgroundColor: bColor || "#00A85A", borderRadius: 20,
            alignItems: 'center',overflow:'hidden'
        }}>
            <div class="d-md-flex flex-md-equal w-100  align-items-center pl-md-3 ml-md-n5 ">
                <div class="  px-md-5 text-center overflow-hidden align-items-center zoomin" style={{
                    backgroundImage: `url(${image})` || "",
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    height: 320,
                }}>

                    <div class=" mx-auto" style={{ width: '80vw' }}></div>
                </div>
                <div class="px-3 px-md-5 text-white overflow-hidden">
                    <div class="pt-md-4 pt-4 animate__animated animate__backInRight animate__slow">
                        <p class='text-wrap'
                            style={{
                                color: 'var(--unnamed-color-333333)',
                                textAlign: 'left',
                                font: 'Bold 20px Raleway',
                                letterSpacing: '1.23px',
                                color: '#ffffff',
                                opacity: 1,
                            }}>
                            {text1 || 'Hi! This is a Header'}
                        </p>
                        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                            {text2.length > 0 && text2.map((item, id) => {
                                return (
                                    <p class='text-wrap' style={{ color: "white", textAlign: "left", lineHeight: 0.9 }} key={id}>
                                        <small> {item} </small>
                                    </p>
                                )
                            })}
                            <a href={isIOS ? 'https://apps.apple.com/ng/app/wakanda-market/id1451309384' : 'https://play.google.com/store/apps/details?id=com.african_founders.Wakanda'} style={{ textDecoration: 'none' }}>
                                <div className='mt-4'>
                                    <button type="button" class="btn btn-outline-light "> {buttonText || 'GET STARTED'}</button>
                                </div>
                          </a>
                        </div>
                    </div>

                </div>

            </div>

        </div >)
}


const Banner3 = (image, text1, text2, buttonText, bColor) => {
    return (
        <div class=' rainbow' style={{
            backgroundColor: bColor || "#00A85A", alignItems: 'center', padding: 20, paddingTop: 50,
            //borderWidth:5, border:'1px dashed #66ff99'
        }}>
            <div class="container d-md-flex flex-md-equal w-100  align-items-center pl-md-3">
                <div class="px-3  px-md-5  text-white overflow-hidden">
                    <div class="pt-md-4 pt-4 animate__animated animate__backInLeft animate__slower ">
                        <h2 class="display-5 text-dark">{text1 || 'Hi! This is a Header'}</h2>
                        <p style={{ fontSize: 12, color: 'black' }}>{text2 || ''}</p>
                        <a href='#more'>  <div className='mt-4'>
                            <button type="button" class="btn btn-outline-dark "> {buttonText || 'GET STARTED'}</button></div></a>
                    </div>

                </div>
                <div class="  px-md-5 text-center overflow-hidden mt-4  align-items-center  zoomin" style={{
                    backgroundImage: `url(${image})` || "",
                    backgroundSize: 'contain',
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    height: 200,
                    marginBottom: 60
                    //textAlign: 'center',

                }}>
                    <div class=" box-shadow mx-auto" style={{ width: '80vw', borderRadius: '21px 21px 0 0' }}></div>
                </div>
            </div>

        </div >
    )
}



const Banner4 = (image, text1, text2, buttonText, bColor) => {
    return (
        <div class='container' style={{
            backgroundColor: bColor || "#00A85A", borderRadius: 20,
            alignItems: 'center', overflow: 'hidden'
        }}>
            <div class="d-md-flex flex-md-equal w-100  align-items-center pl-md-3">

                <div class="px-md-5 text-center overflow-hidden align-items-center zoomin" style={{
                    backgroundImage: `url(${image})` || "",
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    height: 300,
                    marginLeft: -20
                }}>

                    <div class=" mx-auto" style={{ width: '80vw' }}></div>
                </div>
                <div class="   px-3  px-md-5  text-white overflow-hidden">
                    <div class="pt-md-4 pt-4 animate__animated animate__backInRight animate__slow">
                        <h2 class='display-5 text-dark' >
                            {text1 || 'Hi! This is a Header'}
                        </h2>
                        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                            <p class='text-wrap' style={{ color: "black", textAlign: "left", lineHeight: 1.5 }} >
                                {text2}
                            </p>
                        </div>
                    </div>

                </div>

            </div>

        </div >)
}

const Banner5 = (image, text1, text2, buttonText, bColor) => {
    return (
        <div class='container' style={{
            backgroundColor: bColor || "#00A85A", borderRadius: 0,
            alignItems: 'center', height: 130, overflow: 'hidden', marginTop: 40
        }}>
            <a href ={isIOS ? 'https://apps.apple.com/ng/app/wakanda-market/id1451309384' : 'https://play.google.com/store/apps/details?id=com.african_founders.Wakanda'} style={{textDecoration: 'none'}}>
                <div class="d-md-flex flex-md-equal justify-content-between">
                    <div class=" px-md-5  text-white overflow-hidden">
                        <div class=" animate__animated animate__backInLeft animate__slower pt-3 ">
                            <h2 class="" style={{ fontSize: 24 }}>{text1 || 'Hi! This is a Header'}</h2>
                            <p style={{ fontSize: 13 }}>{text2 || 'Malogbe yen wa o!'}</p>
                            <div className=''>
                                <button type="button" class="btn btn-sm btn-outline-light "> {buttonText || 'GET STARTED'}</button>
                            </div>

                        </div>
                    </div>
                <div class=" overflow-hidden d-sm-block d-none zoomin pr-0 mr-md-n5" style={{
                    backgroundImage: `url(${image})` || "",
                    backgroundSize: 'contain',
                    backgroundPosition: 'top right',
                    backgroundRepeat: 'no-repeat',
                    height: 420,
                    clipPath: 'polygon(95% -10%, 0 -80%, 100% 76%)'
                        
                    
                }}>

                    <div class=" box-shadow mx-auto" style={{ width: '40vw', borderRadius: '21px 21px 0 0' }}></div>
                </div>
            </div></a>

        </div >)
}

const Banner6 = (image, text1, text2, buttonText, bColor) => {
    return (
        <div class=' rainbow' style={{
            backgroundColor: bColor || "#00A85A", alignItems: 'center', padding: 20, 
            paddingTop: 50, borderRadius: 20,
            //borderWidth:5, border:'1px dashed #66ff99'
        }}>
            <div class="container d-md-flex flex-md-equal w-100  align-items-center pl-md-3">
                <div class="px-3  px-md-5  text-white overflow-hidden">
                    <div class="pt-md-4 pt-4 animate__animated animate__backInLeft animate__slower ">
                        <h2 class="display-5 text-light">{text1 || 'Hi! This is a Header'}</h2>
                        <p style={{ fontSize: 12, color: 'black' }}>{text2 || ''}</p>
                        <a href='#more'>  <div className='mt-4'>
                            <button type="button" class="btn btn-outline-light "> {buttonText || 'GET STARTED'}</button></div></a>
                    </div>

                </div>
                <div class="  px-md-5 text-center overflow-hidden mt-4  align-items-center  zoomin" style={{
                    backgroundImage: `url(${image})` || "",
                    backgroundSize: 'contain',
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    height: 200,
                    marginBottom: 60
                    //textAlign: 'center',

                }}>
                    <div class=" box-shadow mx-auto" style={{ width: '80vw', borderRadius: '21px 21px 0 0' }}></div>
                </div>
            </div>

        </div >
    )
}
function getAllProductImages(product) {

    let productImages = {};
    let keys = []
    productImages[product.xID] = []
    keys = Object.keys(product)
    keys.forEach(key => {
        if (key.indexOf('pictureURL') > -1) {
            productImages[product.xID].push(product[key])
        }
    })

    return productImages
}

export { uniformImageHolder, nonUniformImageHolder, Header, JobCard, Banner1, Banner2, Banner3, Banner4, Banner5, Banner6,BannerCat, BannerJobs, displayprice, getAllProductImages, Footer2 }