import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import FourZeroFour from './components/FourZeroFour'
import BlogHome from './components/Blog/BlogHome'
import BlogPost from './components/Blog/BlogPost'
import BlogHome2 from './components/Blog/BlogHome2'
import CreatePost from './components/Blog/CreatePost'
import Login from './components/Blog/Login'
import Verification from './components/Blog/Verification'
import './index.css';
//import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom"



const routing = (

  <Router>
    <Switch>
      <Route exact path="/" component={BlogHome2} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/verifyOTP" component={Verification} />
      <Route exact path="/verifyOTP/:phone" component={Verification} />
      <Route exact path="/create-new-post" component={CreatePost} />
      <Route exact path="/post/:blogid/:postid" component={BlogPost} />
      <Route exact path="/:blogid" component={BlogHome} />

      <Route component={FourZeroFour} />
    </Switch>

  </Router>
)

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
