import React, { useEffect, useState } from 'react'
import { uniformImageHolder, displayprice } from '../utilityComponents'
import { Link, Redirect } from 'react-router-dom'
import Skeleton from '@yisheng90/react-loading';
import { Dropdown, ButtonGroup, Button, InputGroup, FormControl } from 'react-bootstrap'
import SignInModule from './SignInModule'
import ProductHeader from '../Header/ProductHeader'
import '../styles/provider.css'
import Footer from '../Footer/footer'
import '../styles/category.css'
import '../Header/ProductHeader.css';
import WakandaLogoWhite from '../../assets/Wakanda-Logo-white.svg'

import banner1image from '../images/Group-674.png'
import { BannerCat, Banner5 } from '../utilityComponents'
import {
    BrowserView,
    MobileView,
    isBrowser,
    isAndroid,
    isIOS,
    isMobile
} from "react-device-detect";
import { Alert } from 'bootstrap';





const productData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9]

const marketing = [
    '- Sell your products and services',
    '- Use the Wakanda Wallet and Escrow Service',
    '- Get free business/e-commerce Website and a lot more…'
]

let URL = "https://cdn.pixabay.com/photo/2017/01/14/10/03/fashion-1979136_1280.jpg"
let providerPhoto = "https://images.pexels.com/photos/4599047/pexels-photo-4599047.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
let bgCover = "https://images.pexels.com/photos/4355345/pexels-photo-4355345.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"





let imageDat = {
    'Fashion': 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FFashion%2Fmens-clothes.jpg?alt=media&token=b2e86dc1-8887-4e77-b112-2fe5aa3980ad',
    'Food': 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FFood%2FFreshFood%2Fcarrots-crate-food-1508666.jpg?alt=media&token=ef7ad2a8-a72a-4d34-a41f-5d1ea6786622',
    'Beauty': 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FBeauty%2FMakeup%2Fassorted-blur-close-up-1115128.jpg?alt=media&token=43abcfff-b9a9-4769-abd4-2719c5ae956b',
    'Electronics': 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FElectronics%2FPhones.jpg?alt=media&token=a4b78d9b-458c-4e92-b766-f34294b275e4',
    'Cars': 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FCars%2Fnew-cars.jpg?alt=media&token=0d2bceee-823e-4402-88ad-b693776f77f0',
    'Health': 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FHealth%2FPharmacies%2FPharmacy.jpg?alt=media&token=db11dfbf-2d57-4e71-8ed8-be3d1dcc0161',
    'Handyman': 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FHandyman%2FPlumbing.jpg?alt=media&token=9f763caf-1a91-439b-b48f-716c37372a5f',
}


let BLOGID = ''

const BlogHome = (props) => {

    const [shopCategory, setShopCategory] = useState("")
    const [shopProducts, setProducts] = useState(productData)
    const [redirect, setRedirect] = useState(false)
    const [url, setURL] = useState('')
    const [blogPictureURL, setBlogPictureURL] = useState(url)
    const [blogSubtitle, setBlogSubtitle] = useState('')
    const [blogTitle, setBlogTitle] = useState('')
    const [blogData, setBlogData] = useState({})
    const [blogDescription, setBlogDescription] = useState('')
    const [blogID, setBlogID] = useState('')



    const fetchBlogData = async () => {
        console.log('launching fetchProductsbyCategory, blogID is', blogID)
        if (!blogID) return
        try {
            const r = await fetch("https://us-central1-afbase-7ee60.cloudfunctions.net/worldGet", {
                method: "POST",
                //mode: "no-cors",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    type: 'getBlog',
                    authPhrase: 'A¶§Ô˝D3ßºƒn',
                    // profileID:'00babaOjaGeneralBlog',
                    blogID: blogID,
                    location: 'lagos'
                })
            });
            const r2 = await r.json();
            console.log('r2 offers fetched in fetchBlogData are: ', r2)
            if (r2.msg == 'SUCCESS') {
                console.log('r2 is xxx', r2)
                let data = r2.response
                setBlogData(data)
                setBlogPictureURL(data.blogPictureURL)
                setBlogSubtitle(data.blogSubtitle)
                setBlogTitle(data.blogTitle)
                setBlogDescription(data.blogDescription)
                setProducts(data.chatGroups)
                //await setPhoneNumber(data.phoneNumber)
            } else {
                console.log('something went wrong... ', r2.msg)
                alert('No Blog found, please try again')
            }

        } catch (err) {
            console.log("Error from firebase using fetchProductsbyCategory is: ", err);

        }

    }

    const parseURLandReturnRelevantID = async () => {
        try {
            const fullURL = window.location.href.split('#')[0]
            const fullURLsubStrings = fullURL.split('/');
            console.log(fullURLsubStrings);

            console.log('fullURLsubStrings[fullURLsubStrings.length - 1] is ', fullURLsubStrings[fullURLsubStrings.length - 1],);
            BLOGID = fullURLsubStrings[fullURLsubStrings.length - 1]
            setBlogID(BLOGID)

        } catch (err) {
            console.log('issues with parsing URL ', err)
        }

    }

    useEffect(() => {
        parseURLandReturnRelevantID()
    }, [])

    useEffect(() => {
        setBlogID(BLOGID)
        fetchBlogData()
    }, [blogID])




    return (
        <div style={{ position: 'relative' }}>
            {redirect && <Redirect to={url} />}

            <div className="nw1-product-smaller-header" style={{ position: 'relative' }}>
                <div className="row"  >
                    <Link to='/' style={{ textDecoration: 'none', width: '100%' }}>
                        <div style={{
                            backgroundImage: `url(${WakandaLogoWhite})`,
                            width: 190,
                            height: 45,
                            backgroundPosition: 'top left',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            paddingBottom: 20
                        }} className='col-lg-3 col-sm-12 mt-2  mb-2'>
                        </div>
                    </Link>
                    <div className="float-right d-none"><p className='sell d-none d-md-block' style={{ fontWeight: 'bold' }}>
                        SELL ON WAKANDA</p>
                    </div>

                </div>
                <div style={{ position: 'absolute', top: 20, right: 20, height: 100, width: 100 }}><SignInModule /></div>
            </div>

            <div style={{ width: '100%', }}><ProductHeader backgroundPictureURL={URL} /></div>

            <div class='shadow-md conatiner-fluid' style={{ paddingBottom: 20, backgroundColor: '#FFF', borderBottomLeftRadius: 20, borderBottomRightRadius: 20, width: '100%', }}>

                <div className='container' style={{
                    display: 'flex', padding: 20, paddingLeft: 30, paddingBottom: 0, alignItems: 'center',
                    marginTop: 30, flexDirection: 'column', justifyContent: 'center',
                }}>
                    <div style={{ justifyContent: 'center', marginTop: -40, }}>
                        {uniformImageHolder(blogPictureURL || URL, 100, 100, '4px solid #F5F5F5', 15)}
                    </div>

                    <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', marginTop: 10, textOverflow: 'ellipsis', overflow: 'hidden', textWrap: 'wrap' }}>
                        {blogTitle && <Link style={{ textDecoration: 'none' }}>
                            <h2 class='text-truncate' style={{ textDecoration: 'none', color: 'black', fontFamily: 'Libre Baskerville', }}><b>{blogTitle || ""}</b></h2>
                        </Link>}
                        <div style={{ border: '1px solid grey', borderWidth: 0, borderTopWidth: 1, borderColor: '#555', display: 'flex', flexDirection: "column", alignItems: 'center', marginTop: 10, }}>
                            <small class='text-truncate text-wrap' style={{ marginTop: 10, fontSize: 14, color: '#666', fontFamily: 'Playfair Display', fontSize: 17, textAlign: 'center' }}>{blogDescription || ""}</small>
                            <small class='text-truncate text-wrap' style={{ marginTop: 5, fontSize: 11, color: '#666', fontFamily: 'Playfair Display', fontSize: 15, textAlign: 'center' }}>~ {blogSubtitle || ""} ~</small>
                        </div>
                    </div>
                </div>
            </div>

            <div className='pt-4'>

            </div>

            <section class='container mt-4' style={{ minHeight: '100vh' }}  >
                <div class='d-flex justify-content-center mt-4 mb-4'>
                    <div class='container mt-4 row'  >
                        {shopProducts.map((item, id) => {
                            if (shopProducts.length == 0) {
                                return (
                                    <div class='col-md-12 ' style={{ height: 50, overflow: 'hidden', textOverflow: 'ellipsis', }}>
                                        <div style={{ width: "100%", marginTop: 10, overflowX: 'hidden', textOverflow: 'ellipsis', }}>
                                            <small class='text-truncate' style={{ fontSize: 14, color: 'black' }} >No Results...</small>
                                        </div>
                                    </div>
                                )
                            }
                            return (
                                <div class='col-md-6 col-sm-12 py-2' key={id}>
                                    <Link target="_blank" to={`/post/${blogID}/${item.xID}`} style={{ textDecoration: 'none' }}>
                                        <div class='shadow-sm pt-0 ml-1 mr-1 zoomin row ' style={{ border: '1px solid rgba(100,100,100,0.1)', borderRadius: 10 }}>
                                            {item.groupPictureURL ? <div class='col-md-4 p-2 pt-0 d-flex justify-content-center align-items-center'
                                                style={{ marginTop: -10, overflowX: 'hidden', textOverflow: 'ellipsis', }}>
                                                {uniformImageHolder(item.groupPictureURL, '100%', '13rem', '', 10)}
                                            </div>
                                                :
                                                <div class='col-md-4 p-2 pt-0 d-flex justify-content-center align-items-center'><Skeleton width={"100%"} height={"12rem"} /></div>}

                                            <div class='col-md-8 pl-2' style={{ padding: 20, paddingBottom: 20, paddingTop: 10, width: "100%", }}>
                                                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', }}>
                                                    <div style={{ width: "100%", marginTop: 10, overflowX: 'hidden', textOverflow: 'ellipsis', textWrap: 'wrap' }}>
                                                        <p style={{ fontSize: 18, fontFamily: 'Abril Fatface' || 'Libre Baskerville' }} ><b>{item.groupTitle || "..."}</b></p>
                                                    </div>
                                                    <div className='p-2 col overflow-hidden text-truncate text-wrap'
                                                        style={{ width: "100%", marginTop: 0, height: 200 }}>
                                                        <p style={{ fontSize: 14, color: 'grey', fontFamily: 'Libre Baskerville' }}> {item.groupDetails || "..."} </p>
                                                    </div>
                                                </div>

                                                <hr />
                                                {/* <div style={{ width: "100%", marginTop: -5, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                    <small class='text-truncate col-md-12  pl-0' style={{ fontSize: 11 }} ><span class='text-success text-truncate'><span style={{ color: 'black' }}>offered by</span> {item.profileNameBusiness || "..."}</span></small>
                                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                                        <button type="button" class="btn btn-lg btn-inline px-2 row mt-2 d-flex justify-content-center align-items-center"
                                                            style={{ width: '100%', backgroundColor: '#4160E0' }}>
                                                            <span className='mx-1' ><small style={{ fontSize: 11 }} class='text-truncate text-white pb-2' >View Details {item.name}</small></span>
                                                        </button>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
            <div class='container mt-n4  p-4' style={{ minHeight: 60, position: 'relative', marginTop: 0 }}>
                {BannerCat(imageDat[shopCategory], 'Enjoy More Benefits from the Wakanda Market App', marketing, 'GET STARTED', '#C1DE76')}
            </div>
            <Footer />
        </div >
    )
}

export default BlogHome