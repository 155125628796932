import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, Redirect } from 'react-router-dom'
import firebase from "firebase";
//import * as Network from "expo-network";
import "firebase/firestore";
//import { Context as AuthContext } from "../../context/AuthContext";
//import AxiosCall from "../../utilities/Axios" 

let PHONE = ''


const Login = () => {
    //const { state, checkNetworkConnection } = useContext(AuthContext);
    const [user, setUser] = useState("");
    const [otpNumber, setOtpNumber] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('')
    const [confirmCodeActivity, setConfirmCodeActivity] = useState(false)
    const [verficationMethod, setVerificationMethod] = useState("SMS")
    const [redirect, setRedirect] = useState(false)

    const parseURLandReturnPHONE = async () => {
        try {
            const fullURL = window.location.href.split('#')[0]
            const fullURLsubStrings = fullURL.split('/');
            console.log(fullURLsubStrings);
            console.log('fullURLsubStrings[fullURLsubStrings.length - 1] is ', fullURLsubStrings[fullURLsubStrings.length - 1]);
            PHONE = fullURLsubStrings[fullURLsubStrings.length - 1]
            setPhoneNumber(PHONE)
        } catch (err) {
            console.log('issues with parsing URL ', err)
        }
    }

    useEffect(() => {
        parseURLandReturnPHONE()
    }, [])

    useEffect(() => {
        setPhoneNumber(PHONE)
    }, [phoneNumber])

    const confirmCode = async () => {
        setConfirmCodeActivity(true)

        try {
            const r = await fetch("https://us-central1-afbase-7ee60.cloudfunctions.net/webVerifyOTP", {
                method: "POST",
                //mode: "no-cors",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    code: otpNumber,
                    authPhrase: "%a#^&$%^&787g",
                    shopName: 'biddymart' || '',
                    phoneNumber
                })
            });
            const r2 = await r.json();
            console.log('r2 confirmCode is ', r2)
            if (r2.msg === "AUTH_SUCCESS") {
                //await firebase.auth().signInWithCustomToken(r2.token);
                //const currentUser = firebase.auth().currentUser
                //console.log('current useeer', currentUser);
                let ID = r2.profileID
                let blog = r2.blogs
                localStorage.setItem('blogProfileID', ID);
                localStorage.setItem('blog', JSON.stringify(blog));
                setConfirmCodeActivity(false)
                setRedirect(true)
            } else if (r2.msg === "INCORRECT_OTP_CODE") {
                alert("You entered an incorrect OTP_CODE, Try again")
                console.log(r2.msg);
                setConfirmCodeActivity(false)
            }
            else if (r2.msg === "INVALID_OTP_CODE") {
                alert("You entered an invalid OTP_CODE, Crosscheck and Try again")
                console.log(r2.msg);
                setConfirmCodeActivity(false)
            } else {
                console.log("An Error ocurred in verifyOTP", r2);
                setConfirmCodeActivity(false)
            }
        } catch (err) {
            await setConfirmCodeActivity(false)
            console.log("ERROR", err);
            alert('Sorry! something went wrong, please try again!')
        }
    }


    /*  useEffect(() => {
         const user = firebase.auth().currentUser;
         setUser(user);
     }, []); */

    return (
        <div
            style={{
                backgroundColor: "#fff",
                width: '100%',
                height: '100%',
                alignItems: "flex-start",
                padding: 50,
                flexDirection: 'row'
            }}
        >
            {redirect && <Redirect to='/' />}
            <div
                style={{
                    padding: 10,
                    alignItems: "flex-start",
                    flex: 1, justifyContent: 'flex-start',
                    marginTop: 100
                }}>

                <p style={{
                    fontFamily: "TTCommons-Medium",
                    color: "#00A85A",
                    fontSize: 24,
                    padding: 2,
                    margin: 2,
                    fontWeight: 'bold',
                }}>
                    OTP
                </p>
                <input
                    style={{
                        color: "#00A85A",
                        fontFamily: "TTCommons-Regular",
                        fontSize: 13,
                        marginVertical: 20,
                        minHeight: 50,
                        marginTop: 0,
                        width: "80%",
                        height: 20,
                        borderWidth: 0,
                        borderBottomColor: "#00A85A",
                        borderBottomWidth: 2,
                        textAlign: 'left'
                    }}
                    placeholder="otp"
                    keyboardType={'number-pad'}
                    onChange={(text) => {
                        setOtpNumber(text.target.value)
                        console.log('texxxxxt', text.target.value);
                    }}
                />

                <div style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}  >
                    <button id="order-button"
                        style={{
                            backgroundColor: '#D0F0C0', alignSelf: "center", padding: 10, paddingRight: 30, paddingLeft: 30,
                            justifyContent: "center", alignItems: "center", borderRadius: 5, marginTop: 20,
                            borderWidth: 2, borderColor: '#00A85A', alignSelf: 'flex-start',
                        }}
                        onClick={async () => {
                            await confirmCode()
                            //alert("it works!")
                        }}
                        disabled={confirmCodeActivity}>
                        <p style={{ color: '#00A85A', fontWeight: "bold", fontSize: 12 }}>Verify</p>
                    </button>
                    {confirmCodeActivity && <div style={{
                        marginTop: 20, alignSelf: 'center', flex: 1, width: '100%',
                        height: '100%', marginLeft: 20, alignSelf: 'flex-start',
                    }}
                    >
                        {/*  <div>
                            <ActivityIndicator
                                size="large"
                                color="#206bdb"
                                style={{
                                    opacity: 1,
                                }}
                                animating={true}
                            />
                        </div> */}

                    </div>}
                </div>

            </div>

        </div>
    );
};
export default Login;
