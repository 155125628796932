import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, Redirect } from 'react-router-dom'
//import AsyncStorage from '@react-native-community/async-storage';
import firebase from "firebase";
//import * as Network from "expo-network";
import "firebase/firestore";
//import { Context as AuthContext } from "../../context/AuthContext";
//import AxiosCall from "../../utilities/Axios"

import { Dots } from 'react-activity';
import 'react-activity/dist/react-activity.css';







const Login = ({ navigation }) => {
  //const { state, checkNetworkConnection } = useContext(AuthContext);
  const [user, setUser] = useState("");
  const [phoneNumber, setPhoneNumber] = useState('');
  const [indicator, setIndicator] = useState(false);
  const [confirmCodeActivity, setConfirmCodeActivity] = useState(false)
  const [verficationMethod, setVerificationMethod] = useState("SMS")
  const [message, setMessage] = useState('')
  const [redirect, setRedirect] = useState(false)





  const signin = async (phoneNumber) => {
    console.log('phoneNumber is ', phoneNumber)
    setMessage('')
    if (phoneNumber == '' || confirmCodeActivity == true) {
      alert('Pls enter a phone nmumber')
      setMessage('Pls enter a phone nmumber')
      return
    }
    if (isNaN(phoneNumber)) {
      alert('Pls enter a phone nmumber')
      setMessage('Pls enter a valid phone nmumber')
      return
    }
    setConfirmCodeActivity(true)
    //navigation.navigate("Verification", { phoneNumber })
    /*  <ActivityIndicator /> */
    try {

      const r = await fetch("https://us-central1-afbase-7ee60.cloudfunctions.net/webSignin", {
        method: "POST",
        //mode: "no-cors",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          authPhrase: '34tets4vs oiue4DVR%E$5',
          //shopName:'biddymart' || '00babaOjaGeneralBlog',
          phoneNumber
        })
      });
      const r2 = await r.json();
      console.log('r2 signin response is ', r2)
      if (r2.msg == 'SUCCESS') {
        console.log('search results for providers', r2.contacts)
        setRedirect(true)
      } else {
        console.log('something went wrong with searching providers... ', r2.msg)
      }
      if (r2.msg === "OTP_SENT_NEW_USER_CREATED" || r2.msg === "OTP_SENT_EXISTING_USER") {
        setRedirect(true)
      }
      /* else if (r2.msg === 'PHONE_NUMBER_TOO_SHORT') {
        alert('Your Phone Number is too short. Please try again.')
        setMessage('Your Phone Number is too short. Please try again.')
      } else if (r2.msg === 'PHONE_NUMBER_TOO_LONG') {
        alert('Your Phone Number is too long. Please try again.')
        setMessage('Your Phone Number is too long. Please try again.')
      } else {
        setMessage('Something went wrong. Please try again.')
        console.log("sign in message error", r2);
      } */
      setConfirmCodeActivity(false)
    } catch (err) {
      setConfirmCodeActivity(false)
      console.log("ERROR", err);
    }
  }


  /* useEffect(() => {
    const user = firebase.auth().currentUser;
    setUser(user);
    
  }, []); */

  return (
    <div
      style={{
        backgroundColor: "#fff",
        width: '100%',
        height: '100%',
        alignItems: "flex-start",
        padding: 50,
        flexDirection: 'row'
      }}
    >
      {redirect && <Redirect to={`/verifyOTP/${phoneNumber}`} />}

      <div
        // keyboardShouldPersistTaps='handled'
        // keyboardShouldPersistTaps='always'
        style={{
          padding: 10,
          alignItems: "flex-start",
          flex: 1, justifyContent: 'flex-start',
          marginTop: 100
        }}>

        <p style={{
          fontFamily: "TTCommons-Medium",
          color: "#00A85A",
          fontSize: 24,
          padding: 2,
          margin: 2,
          fontWeight: 'bold',
        }}>
          Phone Number
         </p>
        <input
          style={{
            color: "#00A85A",
            fontFamily: "TTCommons-Regular",
            fontSize: 13,
            marginVertical: 20,
            minHeight: 50,
            marginTop: 0,
            width: "80%",
            height: 20,
            borderWidth: 0,
            borderBottomColor: "#00A85A",
            borderBottomWidth: 2,
            textAlign: 'left'
          }}
          placeholder="phone number"
          // keyboardType={'number-pad'}
          //value={phoneNumber}
          onChange={(text) => {
            setPhoneNumber(text.target.value)
            console.log('texxxxxt', text.target.value);
          }}
        />
        <div>
          <p style={{ fontSize: 10, color: 'blue' }}>
            {message}
          </p>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}  >
          <button id="order-button"
            style={{
              backgroundColor: '#D0F0C0', alignSelf: "center", padding: 10, paddingRight: 30, paddingLeft: 30,
              justifyContent: "center", alignItems: "center", borderRadius: 5, marginTop: 20,
              borderWidth: 2, borderColor: '#00A85A', alignSelf: 'flex-start',
            }}
            onClick={async () => {
              signin(phoneNumber)
              // alert("it works!")
            }}
            disabled={confirmCodeActivity}>
            <p style={{ color: '#00A85A', fontWeight: "bold", fontSize: 12, textAlign: 'center', }}>LOG IN</p>
          </button>
          {confirmCodeActivity && <div style={{
            marginTop: 10, alignSelf: 'center', flex: 1, width: '100%',
            height: '100%', marginLeft: 20, alignSelf: 'flex-start',
          }}
          >
            <div>
              <Dots color={"#00A85A"} />
            </div>

          </div>}
        </div>

      </div>

    </div>
  );
};
export default Login;
