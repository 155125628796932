import React, { Component } from 'react'
import iosDownload from "./images/apple.png"
import googleDownload from "./images/google.png"


class Download extends Component {

    render() {
        return (
            <div class='container col-md-8 ' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', paddingLeft: 10 }}>

                <a href="https://itunes.apple.com/ng/app/wakanda-market/id1451309384?mt=8" target="_blank"  >
                    <div class='col-md-12'
                    style={{
                        backgroundImage: `url(${iosDownload})` || "",
                        backgroundSize: 'contain',
                        backgroundPosition: 'center center',
                        backgroundRepeat: 'no-repeat',
                        width:150,
                        height: 100,  
                    }}>
                    </div> 
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.african_founders.Wakanda" target="_blank" style={{marginLeft:10}} >
                    <div class='col-md-12'
                    style={{
                        backgroundImage: `url(${googleDownload})` || "",
                        backgroundSize: 'contain',
                        backgroundPosition: 'center center',
                        backgroundRepeat: 'no-repeat',
                        width:150,
                        height: 100,  
                    }}>
                    </div> 
                </a>
                {/* <div class='col-md-6'>
                    <a href="https://play.google.com/store/apps/details?id=com.african_founders.Wakanda" target="_blank" ><img class="animated bounceInUp" src={googleDownload} style={{ width: '100%', height: 40, marginLeft: 20 }} alt="" /></a>
                </div> */}

            </div>
        );
    }
}

export default Download;