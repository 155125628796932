import React, { useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { Dropdown, ButtonGroup, Button, InputGroup, FormControl } from 'react-bootstrap'
import '../styles/provider.css'
import {
    BrowserView,
    MobileView,
    isBrowser,
    isAndroid,
    isIOS,
    isMobile
} from "react-device-detect";



const SignInModule = (props) => {

    const [blogData, setBlogData] = useState({})
    const [blogID, setBlogID] = useState('')
    const [isSignedIn, setIsSignedIn] = useState(false)
    const [redirect, setRedirect] = useState(false)
    const [url, setUrl] = useState('')

    const getSavedData = () => {
        let ProfileID = localStorage.getItem('blogProfileID');
        let blog = JSON.parse(localStorage.getItem('blog'));
        console.log('profileID is ', ProfileID)
        console.log('blog is ', blog)
        //setBlogData(blog)
        //setProfileID(ProfileID)
        if ((blog != null) && (ProfileID != null)) {
            setIsSignedIn(true)
        }
    }


    useEffect(() => {
        getSavedData()
    }, [])


    return (
        <div >
            {redirect && <Redirect to={url} />}

            { <div
                //className='mt-4 mb-2 p-4 shadow-sm' 
                style={{
                    //backgroundColor: '#F1FFF1', width: '100%', borderRadius: 10,
                    //borderWidth: 1, border: '1px #99ff99 solid', 
                    display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'
                }}>
                {!isSignedIn && <div style={{ color: '#62A362', display: 'flex', justifyContent: 'flex-end' }}
                    onClick={async () => {
                        await setUrl('/login')
                        setRedirect(true)
                    }}
                >
                    <button type="button" class="btn btn-success"> {'Sign In'}</button>
                </div>}
                {isSignedIn && <div style={{ color: '#FD329F', display: 'flex', justifyContent: 'flex-end', marginLeft: 10 }}
                    onClick={async () => {
                        await setUrl('/create-new-post')
                        setRedirect(true)
                    }}
                >
                    <button type="button" class="btn btn-success" style={{ backgroundColor: '#ED329F' }}> {'Create Post'}</button>
                </div>}
            </div>}
        </div >
    )
}

export default SignInModule